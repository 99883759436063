import './App.css';
import React, { lazy, useEffect, useState, Suspense } from 'react';
import { Route, useLocation, Routes, Navigate } from 'react-router-dom';
import { parseUrl } from './util/Utils';
import UserContext from './withUserContext';
import { setLocalMap, i18n } from './util/I18nUtils';
import I18N from './util/I18nUtils';
import axios from './util/http';

// 路由页面懒加载
const Home = lazy(() => import('./pages/Home/Home'));
const Cash = lazy(() => import('./pages/Cash/Cash'));
const Success = lazy(() => import('./pages/Success/Success'));
const Error = lazy(() => import('./pages/Error/Error'));
const PrivilegeDialog = lazy(() => import('./pages/PrivilegeDialog/PrivilegeDialog'));

function App() {
  const pathname = useLocation().pathname;
  const [context, setContext] = useState({});
  const [flag, setFlag] = useState(false);
  const search = useLocation().search;
  const { userId, code, source, version, locale, payEnable, internationalEdition } = parseUrl(window.location.href);
  let real_locale = 'zh_CN';

  if (locale) {
    if (locale === 'en_US' || locale === 'ja_JP') {
      real_locale = 'en_US';
    }
  }

  useEffect(() => {
    if (Object.keys(I18N.LOCAL_MAP).length === 0) {
      axios.post('/locale', { locale: real_locale }).then((res) => {
        if (res && res.data) {
          const data = res.data;

          if (data.status === 200) {
            setLocalMap(data.data.localMap);
            setFlag(true);
          }
        }
      });
    } else {
      setFlag(true);
    }
  }, []);

  useEffect(() => {
    if (pathname.includes('/home') || pathname.includes('/cash') || pathname.includes('/privilegeDialog')) {
      const fromIframe = window.self !== window.top;
      // const fromIframe = true;
      setContext({
        source,
        version,
        publicKey: process.env.REACT_APP_RSA_PUBLIC_KEY,
        userId,
        code,
        fromIframe,
        locale: real_locale,
        payEnable: payEnable !== 'false',
        internationalEdition: internationalEdition !== 'false',
      });
    }
  }, [pathname]);

  return (
    flag && (
      <UserContext.Provider value={context}>
        <Suspense fallback={<div></div>}>
          <div className='App'>
            {/* 注册路由 */}
            <Routes>
              <Route path='/home' Component={Home}></Route>
              <Route path='/cash' Component={Cash}></Route>
              <Route path='/success' Component={Success}></Route>
              <Route path='/error' Component={Error}></Route>
              <Route path='/privilegeDialog' Component={PrivilegeDialog}></Route>
              <Route path='/' element={<Navigate to={`/home${search}`} replace={true} />} />
              <Route path='*' element={<Navigate to={`/error?errInfo=${I18N.PAGE_NOT_EXIST}`} replace={true} />} />
            </Routes>
          </div>
        </Suspense>
      </UserContext.Provider>
    )
  );
}

export default App;
